import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { DefaultResponse } from 'app/types/api-response/api-response.type';

const API_URL = environment.backUrl;

@Injectable({
  providedIn: 'root'
})
export class AppService {
	public onChangeLang: BehaviorSubject<any> = new BehaviorSubject(null);
	public onCloseSidebar: BehaviorSubject<any> = new BehaviorSubject(null);
	public onShowMessage: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(
		private _httpClient: HttpClient,
		private router: Router) {}

	private get accessToken(): string {
		return localStorage.getItem('gt-token') ?? '';
	}

	private createHeaders(): HttpHeaders {
		return new HttpHeaders({ 'Authorization': `Bearer ${this.accessToken}` });
	}

	getAssets(urlEndPoint: string, parameters: any = {}): Promise<any> {
		return new Promise((resolve, reject) => {
		this._httpClient.get(`assets/${urlEndPoint}`, { responseType: 'blob' })
			.toPromise()
			.then((blob: Blob) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.onerror = () => reject('Error al cargar el video');
				reader.readAsDataURL(blob);
			})
			.catch(err => {
				console.error(err);
				reject(err);
			});
		});
	}

	get(urlEndPoint: string, parameters: any = {}, type: string | null = null, customMessage: {success: string, error: string} = {success:'', error:''}): Promise<any> {
		const headers = this.createHeaders();

		return this._httpClient.get<DefaultResponse>(`${API_URL}api/${urlEndPoint}`, {headers, params: parameters})
		.toPromise()
		.then(r => {
			if (r.status === 'success') {
				return r.data;
			} else {
				throw new Error('Error en la respuesta del servidor');
			}
		})
		.catch(err => {
			console.log(err);
			throw err;
		});
	}

	post(urlEndPoint: string, payload: any = {}, type: string = 'post', customMessage: {success: string, error: string} = {success:'', error:''}): Promise<any> {
		let headers = this.createHeaders();
		
		if (!(payload instanceof FormData)) {
			headers = headers.set('Content-Type', 'application/json');
			payload = JSON.stringify(payload);
		}

		return this._httpClient.post<DefaultResponse>(`${API_URL}api/${urlEndPoint}`, payload, {headers})
		.toPromise()
		.then(r => {
			if (r.status === 'success') {
				return r.data;
			} else {
				console.error("[GO TALKS] Error on API:", r);
				throw new Error('Error en la respuesta del servidor');
			}
		})
		.catch(err => {
			console.log(err);
			throw err;
		});
	}

	put(urlEndPoint: string, payload: any = {}): Promise<any> {
		const headers = this.createHeaders();

		return this._httpClient.put<DefaultResponse>(`${API_URL}api/${urlEndPoint}`, payload, {headers})
		.toPromise()
		.then(r => {
			if (r.status === 'success') {
				return r;
			} else {
				throw new Error('Error al actualizar');
			}
		})
		.catch(err => {
			console.log(err);
			throw err;
		});
	}

	delete(urlEndPoint: string, payload: any): Promise<any> {
		const headers = this.createHeaders();

		return this._httpClient.delete<DefaultResponse>(`${API_URL}api/${urlEndPoint}`, {headers: headers, body: payload})
		.toPromise()
		.then(r => {
			if (r.status === 'success') {
			return r;
			} else {
			throw new Error('Error al eliminar');
			}
		})
		.catch(err => {
			console.log(err);
			throw err;
		});
	}
}
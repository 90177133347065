import { Injectable } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ListQuery } from 'app/types/base.type';

import { audioStaked } from 'app/types/recorder/recorder.type';

@Injectable({
	providedIn: 'root'
})

export class BaseService {

	public base_path: string = 'api';

	public cacheAvailable: boolean = false;
	private cache = new Map<string, any>();

	levels = [
		{ id: 1, name: 'Level A1 ( Beginner )', miniName: 'A1' },
		{ id: 2, name: 'Level A2 ( Elementary )', miniName: 'A2' },
		{ id: 3, name: 'Level B1 ( Intermediate )', miniName: 'B1' },
		{ id: 4, name: 'Level B2 ( Upper Intermediate )', miniName: 'B2' },
		{ id: 5, name: 'Level C1 ( Advanced )', miniName: 'C1' },
		{ id: 6, name: 'Level C2 ( Proficiency )', miniName: 'C2' }
	];

	public beforePost: BehaviorSubject<any>;
	public afterPost: BehaviorSubject<any>;

	public beforePut: BehaviorSubject<any>;
	public afterPut: BehaviorSubject<any>;

	public beforeDelete: BehaviorSubject<any>;
	public afterDelete: BehaviorSubject<any>;

	public onCreated: BehaviorSubject<any>;
	public onUpdated: BehaviorSubject<any>;
	public onRemoved: BehaviorSubject<any>;

	public requireCloseSidebar: BehaviorSubject<any>;
	public requireShowHideSidebar: BehaviorSubject<any>;
	public requireHidePageHeader: BehaviorSubject<any>;

	public refreshTrigger: BehaviorSubject<any>;

	constructor(
		public _appService: AppService		
	) {
		this.beforePost     = new BehaviorSubject(null);
		this.afterPost      = new BehaviorSubject(null);
		this.beforePut      = new BehaviorSubject(null);
		this.afterPut       = new BehaviorSubject(null);
		this.beforeDelete   = new BehaviorSubject(null);
		this.afterDelete    = new BehaviorSubject(null);
		this.onCreated		= new BehaviorSubject(null);
		this.onUpdated		= new BehaviorSubject(null);
		this.onRemoved		= new BehaviorSubject(null);
		this.requireCloseSidebar	= new BehaviorSubject(null);
		this.requireShowHideSidebar = new BehaviorSubject(null);
		this.requireHidePageHeader	= new BehaviorSubject(null);
		this.refreshTrigger = new  BehaviorSubject(null);
	}

	get(id:any) {
		return this._appService.post(this.base_path + 'get', {id: id});
	}

	getAll( payload:ListQuery = null ) {

		if (!payload) {
			payload = new ListQuery();
			payload.disabledPagination = true;
		}
	  
		const cacheKey = this.generateCacheKey(payload);
	  
		if (this.cacheAvailable && this.cache.has(cacheKey)) {
			return Promise.resolve(this.cache.get(cacheKey));
		}
	  
		return this._appService.post(this.base_path + 'get-all', payload, "")
			.then(response => {
				if (this.cacheAvailable) {
					this.cache.set(cacheKey, response);
				}
				return response;
			});
	}

	private generateCacheKey(payload: ListQuery): string {
		return JSON.stringify({
			q: payload.q,
			qList: payload.qList,
			qListObj: payload.qListObj,
			orderBy: payload.orderBy,
			currentPage: payload.currentPage,
			recordsPerPage: payload.recordsPerPage
		});
	}
	
	clearCache() {
		this.cache.clear();
	}

	getCount( ) {
		return this._appService.post( this.base_path +  'get-count', null, "");
	}

	create( payload:any ) {
		console.log("create");
		return this._appService.post( this.base_path +  'create', payload);
	}

	update( payload:any ) {
		return this._appService.post( this.base_path +  'update', payload);
	}

	remove( payload:any ) {
		return this._appService.post( this.base_path +  'remove', payload);
	}

	put(payload:any) {
		return this._appService.put(this.base_path, payload);
	}

	post(payload:any) {
		return this._appService.post(this.base_path, payload, "");
	}

	delete(payload:any) {
		return this._appService.delete(this.base_path, payload);
	}

	/**
     * Generates a random id
     *
     * @param length
     */
    randomId(length: number = 10): string
    {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let name = '';

        for ( let i = 0; i < 10; i++ )
        {
            name += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return name.slice(length * -1);
    }

	audioStackToFormData( audioInput: audioStaked ) {
		const formData = new FormData();
      	formData.append('audio', audioInput.audio, 'grabacion.webm');
		formData.append('time', audioInput.time.toString());
		formData.append('transform', audioInput.transform.toString());

		return formData;
	}

	getCategoryOnDeep(categories: any, name: any): null {

		let categoryFound = null;
		categories.forEach((c: any) => {
			if( c.name == name ) {
				categoryFound = c;
				return;
			}

			if (c.childrens) {
				let cFound = this.getCategoryOnDeep(c.childrens, name);
				if (cFound) {
					categoryFound = cFound;
					return
				}
			}
		});

		return categoryFound;
	}

	getLevelNameById(langLevelId: number) {
		let name = "A1 - C1"

		this.levels.forEach( l => {
			if (l.id == langLevelId) {
				name = l.name;
			}
		} )

		return name;
	}

	getDays() {
		let daysOfWeek: { id: number, name: string, fullname: string, date?: Date, dd?: string }[] = [
			{ id: 0, name: 'Sun', fullname: 'Sunday', date: null },
			{ id: 1, name: 'Mon', fullname: 'Monday', date: null },
			{ id: 2, name: 'Tue', fullname: 'Tuesday', date: null },
			{ id: 3, name: 'Wed', fullname: 'Wednesday', date: null },
			{ id: 4, name: 'Thu', fullname: 'Thursday', date: null },
			{ id: 5, name: 'Fri', fullname: 'Friday', date: null },
			{ id: 6, name: 'Sat', fullname: 'Saturday', date: null }
		];

		return daysOfWeek;
	}

	isJsonString(str: string): boolean {
		try {
			console.log(str);
		  	JSON.parse(str);
		} catch (e) {
		  	return false;
		}
		return true;
	}

	cleanText(text: string): string {
		if (text !== null) {
			let auxText = text;
			auxText = auxText.toLowerCase();
			auxText = auxText.replace(/\s/g, '');
			auxText = auxText.replace(/[^\w\s]/g, '');
			return auxText.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
		}
		return "";
	}

	formatDateLocal(date: Date): string {
		const pad = (num: number) => num.toString().padStart(2, '0');
		
		return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
	}
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    backUrl: "https://beta.go-talks.com/",
    isApk: false,
    production: false,
    frontUrl: "https://beta.go-talks.com/",
    firebase: {
        apiKey: "AIzaSyCpX_KkFCZoEOdMGE9DjfzYdV-bUkZtM2o",
        authDomain: "go-talks-bf428.firebaseapp.com",
        projectId: "go-talks-bf428",
        storageBucket: "go-talks-bf428.appspot.com",
        messagingSenderId: "1442497070",
        appId: "1:1442497070:web:8cb234fa2d799453c9d5bf",
        measurementId: "G-PL0Q6M5VSW"
    },
    bugsnag: {
        enabled: false,
        apiKey: 'df29977d16bba0bf65196e5a987efa40'
    },
    googleClientFirebase: ""
};